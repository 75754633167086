/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

:root {
    --cart-overlay-totals-background: var(--secondary-base-color);
    --cart-overlay-divider-background: #d8d8d8;
    --cart-overlay-promo-background: #fff;
    --cart-overlay-width: 400px;
}

.CartOverlay {
    position: fixed;
    inset-block-start: 0;
    width: 540px;
    height: 100vh;
    padding: 30px 40px;
    border-style: none;
    opacity: 1;
    inset-inline-end: -540px;
    transition-property: right;
    transition-duration: .2s;
    transition-timing-function: ease-out;
    display: none;

    @include isArabic {
        transition-property: left;
    }

    @include after-mobile {
        inset-inline-end: 11px;
    }

    @include large-desktop {
        inset-block-start: 0;
        display: block;
        padding: 20px;
        inset-inline-end: -400px;
        width: 400px;
        overflow-y: scroll;
    }

    @include extra-large-desktop {
        padding: 25px;
        inset-inline-end: -480px;
        width: 480px;
    }

    @include after-extra-large-desktop {
        padding: 30px 40px;
        width: 540px;
        inset-inline-end: -540px;
    }

    &_isAfterAddToCart {
        display: none;

        @include small-desktop {
            transition: opacity .2s ease-out;
            opacity: 0;
            display: block;
            padding: 20px;
            inset-block-start: calc(var(--cart-overlay-offset));
            inset-inline-end: 0;
            width: 100%;
            overflow-y: scroll;
            height: calc(100vh - var(--cart-overlay-offset));
        }

        @include large-desktop {
            display: none;
        }
    }

    &_isVisible {
        inset-inline-end: 0;

        &.CartOverlay_isAfterAddToCart {
            opacity: 1;
        }
    }

    &-Empty {
        padding: 10px 30px 15px;
        font-size: 20px;
        font-weight: bold;
    }

    &-Actions {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &-CheckoutButton {
        width: 100%;
    }

    &-CartButton {
        margin-block-start: 17px;
        font-size: 12px;
        text-transform: uppercase;
        border-bottom: solid 2px var(--color-silver);
        padding-block-end: 2px;
        font-weight: 700;

        &:hover {
            text-decoration: none;
        }

        @include animated-bottom-border(#ccc, #777);
    }

    &-Title {
        text-transform: uppercase;
        color: var(--color-gray-text);
        margin-block-end: 30px;
        font-weight: bold;
        font-size: 12px;
    }

    &-Close {
        position: absolute;
        inset-block-start: 12px;
        inset-inline-end: 10px;
        padding: 6px 10px;

        @include extra-large-desktop {
            inset-block-start: 15px;
            inset-inline-end: 15px;
            padding: 7px 11px;
        }

        @include after-extra-large-desktop {
            inset-block-start: 20px;
            inset-inline-end: 20px;
            padding: 7px 12px;
        }

        .Icon {
            &-Close {
                display: inline-block;
                transition: opacity .15s ease 0s;
                width: 20px;
                height: 20px;

                @include extra-large-desktop {
                    width: 22px;
                    height: 22px;
                }

                @include after-extra-large-desktop {
                    width: 24px;
                    height: 24px;
                }

                &:hover {
                    opacity: .5;
                }
            }
        }
    }

    &-Discount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        border-bottom: solid 1px var(--color-input-border);

        dt {
            display: flex;
            flex-direction: column;
            padding-block-end: 10px;
        }
    }

    &-SubTotal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        padding: 8px 0;
        border-bottom: solid 1px var(--color-input-border);

        dd {
            font-size: 13px;
        }
    }

    &-Total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px 0 25px;
        text-transform: uppercase;

        dt {
            font-weight: bold;
        }

        dd {
            font-size: 19px;
            font-weight: bold;
        }
    }

    &-Group {
        padding: 13px 23px 0;
    }

    .PromptFreeShippingMessage {
        padding-block-end: 5px;
    }
}
