/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

:root {
    --cart-item-heading-color: #000;
    --cart-item-subheading-color: #888;
    --cart-item-option-color: #adadad;
    --cart-item-background: #fff;
    --cart-item-actions-background: var(--secondary-base-color);
    --cart-item-actions-color: #000;
    --cart-item-divider-color: var(--expandable-content-divider-color);
    --cart-item-action-border: var(--cart-item-divider-color);
}

.CartItem {
    --header-color: var(--cart-item-actions-color);

    border-bottom: 1px solid var(--cart-item-divider-color);
    margin-block-end: 29px;
    padding-inline-start: 0;

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;
    }

    &-Option {
        padding-inline-start: 0;
        margin-block-end: 0;

        &::before {
            content: none;
        }

        &:last-child {
            margin-block-end: 0;
        }

        &AttributeLabel {
            font-size: 12px;
            color: #979797;
        }

        &AttributeValue {
            font-size: 12px;
            color: var(--color-gray-nero);
            font-weight: bold;
        }

        @include isArabic {
            display: flex;
        }

        .CartItem {
            &-OptionAttributeValue {
                @include isArabic {
                    order: 2;
                    margin-inline-end: 2px;

                    // Fix edge-case with Linux (Ubuntu?) font rendering
                    display: inline-flex;
                    align-items: center;
                }
            }

            &-OptionAttributeLabel {
                @include isArabic {
                    order: 1;
                }
            }
        }
    }

    &-Content {
        padding-block-end: 33px;
        padding-inline-start: 10px;

        &_isLikeTable {
            @include after-mobile {
                padding-block-end: 0;
                min-height: 5rem;
                align-self: center;
            }
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 124px auto;
        background: var(--cart-item-background);
    }

    &-Options {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-block-start: 10px;

        &_isLikeTable {
            @include after-mobile {
                flex-grow: 1;
            }
        }
    }

    &-ItemOptionsWrapper {
        &_isLikeTable {
            @include desktop {
                max-width: 45%;
            }
        }
    }

    &-ItemOption {
        display: flex;
        margin-block-end: .5rem;
        margin-inline-end: .5rem;
    }

    &-PictureWrapper {
        height: calc(100% - 15px);

        >div {
            height: 0;
        }
    }

    &-ItemOptionLabel {
        align-self: center;
        font-weight: bold;
        margin-inline-end: .5rem;
    }

    &-Heading,
    &-SubHeading {
        font-size: 14px;
        line-height: 1.2;
        flex-grow: 1;
        width: 100%;
        margin-block-end: .5rem;
    }

    &-Heading {
        color: var(--color-gray-nero);
        font-size: 18px;
        font-weight: bold;
        transition: color .15s;

        @include tablet {
            display: block;
            max-width: 400px;
            padding-inline-end: 15px;
        }

        &:hover {
            color: var(--color-brown-mongoose);
            text-decoration: none;
        }

        &:focus {
            text-decoration: none;
        }
    }

    &-SubHeading {
        color: var(--cart-item-subheading-color);
    }

    &-Picture {
        padding: 62px;
    }

    &-Info {
        margin-block-start: 14px;
        display: block;

        @include desktop {
            position: absolute;
            width: fit-content;
            inset-inline-end: 0;
            inset-block-start: 0;
            inset-block-end: 0;
            margin: auto;
        }

        @include small-desktop {
            display: flex;
            align-items: center;
            position: relative;
            inset-inline-end: auto;
            inset-block-end: auto;
            inset-inline-start: auto;
            margin: 14px 0 0 0;
        }
    }

    &-Quantity {
        margin-inline-end: 33px;
        font-size: 18px;

        @include isArabic {
            margin-inline-start: 33px;
            margin-inline-end: 0;
        }
    }

    &-Price {
        line-height: inherit;
        font-size: 12px;
        color: var(--color-gray-nero);
        height: fit-content;
        margin-block-start: 1.1rem;

        data {
            font-weight: bold;
        }

        @include desktop {
            margin-block-start: 1.4rem;
            font-size: 14px;
        }

        @include small-desktop {
            margin-block-start: 0;
            font-size: 18px;
        }

        .CartPage & {
            display: flex;
            flex-direction: column-reverse;
        }

        &_isDiscounted {
            @include large-desktop {
                display: flex;
                flex-direction: column;
            }

            @include extra-large-desktop {
                display: block;
            }

            .ProductPrice {
                &-HighPrice {
                    width: fit-content;

                    @include isArabic {
                        margin-inline-end: auto;
                    }

                    data {
                        .CartPage & {
                            font-size: 12px;

                            @include desktop {
                                font-size: 17px;
                            }
                        }

                        .CartOverlay & {
                            font-weight: normal;
                            font-size: 18px;
                        }
                    }
                }
            }

            span {
                &:first-of-type {
                    data {
                        .CartPage & {
                            font-size: 12px;
                            color: var(--color-red);
                            margin-inline-end: .3rem;

                            @include isArabic {
                                margin-inline-end: unset;
                                margin-inline-start: .3rem;
                            }

                            @include desktop {
                                font-size: 22px;
                                margin-inline-end: 0;
                            }
                        }

                        .CartOverlay & {
                            color: var(--color-brown-mongoose);
                            margin-inline-end: 1em;
                        }
                    }
                }
            }
        }
    }

    &-Actions {
        opacity: 0;
        padding: 0 1rem;
        max-height: 0;
        pointer-events: none;
        will-change: max-height, opacity, padding;
        transition-property: padding, max-height;
        transition-duration: 200ms;
        position: relative;
        inset-block-end: auto;
        inset-inline-end: auto;
        width: 100%;

        &_isEditing {
            transition-property: padding, max-height, opacity;
            padding: 1.5rem 0 0;
            opacity: 1;
            max-height: 5rem;
            pointer-events: all;

            @include desktop {
                padding: 2.5rem 0 0;
            }
        }

        &::after,
        &::before {
            display: none;
        }

        &::before {
            inset-block-start: 0;
            z-index: 1;
            border-color: var(--cart-item-action-border) transparent transparent transparent;
        }
    }

    &-Delete,
    &-Edit {
        width: fit-content;
        border-bottom: 1px solid #{$black};
        font-size: .8rem;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            color: #{$c-red};
            border-bottom-color: #{$c-red};
        }

        span {
            font-weight: bold;
        }

        @include small-desktop {
            font-size: 1rem;
        }
    }

    &-Edit {
        margin-inline-start: 1.5rem;

        @include desktop {
            margin-inline-start: 2.7rem;
        }
    }

    & &-Qty { // hack to obtain required specificity
        margin-block-start: 0;
        display: flex;

        input {
            background: transparent;
            border: 0;
            color: var(--cart-item-actions-color);
            height: 28px;
            font-weight: 400;
            text-align: center;
            padding: 0;
            order: 2;
            width: 44px;
            min-width: 44px;
            font-size: 16px;
        }

        button {
            position: relative;
            order: 1;
            border: 1px solid var(--cart-item-actions-color);
            width: 28px;
            height: 28px;
            inset-block-start: 0;
            inset-inline-start: 0;
            color: var(--cart-item-actions-color);
            background: transparent;

            &:first-of-type {
                inset-inline-start: 0;
                order: 3;
            }

            &:last-of-type {
                inset-inline-end: 0;
                order: 1;
            }

            span {
                @include mobile {
                    inset-block-start: -2px;
                }
            }
        }
    }

    &-QtyWrapper {
        display: inline-block;
        vertical-align: middle;
        width: 120px;

        @include desktop {
            margin-inline-end: 2.5rem;
            height: 54px;
        }

        select {
            @include desktop {
                padding-block-start: 22px;
            }
        }
    }

    .FieldSelect {
        width: 100%;
    }

    .FieldSelect-Option {
        border-left: 1px solid #{$c-input-border};
        border-right: 1px solid #{$c-input-border};
    }

    .FieldSelect-Options {
        overflow-y: auto;
    }

    &-QtyLabel {
        position: absolute;
        z-index: 2;
        color: #{$c-input-label};
        font-size: .6rem;
        text-transform: uppercase;
        inset-block-start: .6rem;
        inset-inline-start: 1.3rem;

        @include isArabic {
            inset-inline-end: 1.3rem;
        }

        @include desktop {
            font-size: .9rem;
        }

        @include small-desktop {
            font-size: .8rem;
        }
    }

    &-StockWrapper {
        height: fit-content;
        font-family: var(--font-FFDIN);
        font-size: var(--font-size-nano);
        color: var(--color-input-label);
        display: flex;
        justify-content: center;
        margin-block-start: 3rem;

        @include desktop {
            font-size: var(--font-size-normal-small);
        }
    }

    &-StockStatusLabel {
        margin-inline-start: 5px;
        inset-block-start: 3px;

        @include after-mobile {
            inset-block-start: 1px;
        }
    }

    &-Stock {
        height: fit-content;
        font-family: var(--font-FFDIN);
        font-size: var(--font-size-nano);
        color: var(--color-input-label);
        display: block;
        text-align: center;
        margin-block-start: 1rem;

        @include desktop {
            font-size: var(--font-size-normal-small);
        }

        &Icon {
            content: url('../#{$icons-path}close-red-icon.svg');
            width: 8px;
            height: 8px;
            margin: 2px auto;
            display: block;

            @include desktop {
                margin: 3px;
            }

            &_inStock {
                content: url('../#{$icons-path}check-green-icon.svg');
            }

            &Wrapper {
                margin-inline-end: .5rem;
                border: 1px solid var(--color-red);
                border-radius: 50%;
                height: 16px;
                width: 16px;
                display: inline-block;
                vertical-align: middle;

                @include desktop {
                    margin-inline-end: .8rem;
                }

                &_inStock {
                    border-color: var(--color-green);
                }
            }
        }
    }
}
