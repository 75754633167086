/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

.CartCoupon {
    >form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &-Input {
        display: block;

        input {
            width: 100%;
        }
    }

    &-Button {
        margin-inline-start: 0;
        font-size: 1.1rem;
        padding: .8rem 2rem 1rem;
        margin-block-start: 1rem;
        width: fit-content;

        @include desktop {
            font-size: 1.4rem;
        }

        &:not([disabled]) {
            &:hover {
                background-color: #{$c-gray-button};
            }
        }
    }

    .Button[disabled] {
        opacity: 1;
    }
}
