/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_AddToCartPopup
 * @author      Deniss Strombergs <info@scandiweb.com>
 */

.AddToCartPopup {
    &_isVisible {
        height: -webkit-fill-available;

        @include mobile {
            height: var(--window-inner-height-checkout);
        }

        inset-block-start: var(--visible-global-banner-part);
    }

    @include small-desktop {
        display: none;
    }

    .Popup {
        &-Header {
            padding: 21px 23px;
            border-bottom: solid 1px $c-gray-gainsboro;

            @include after-mobile {
                button.Popup-CloseBtn {
                    inset-inline-start: 0px;

                    &::before, &::after {
                        inset-block-start: 0px;
                    }
                }
            }
        }

        &-Heading {
            font-size: 14px;
            margin-block-end: 0px;

            @include after-mobile {
                font-size: 16px;
            }
        }

        &-CloseBtn {
            @include mobile {
                inset-block-start: -6px;
            }
        }

        &-Content {
            inset-block-start: 0px;
            position: absolute;
            width: 100%;
            padding: 0px;

            @include after-mobile {
                width: 550px;
                inset-block-start: auto;
            }
        }
    }

    &-Links {
        margin: 15px 0px 19px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0px 23px;
    }

    .Button-AddToCart {
        margin: 0px 23px;
        font-size: 14px;
        display: block;
        padding: 9px 16px 10px;
        text-align: center;

        @include after-mobile {
            font-size: 15px;
        }
    }

    &-AddToCartLink, &-ContinueShoppingLink {
        font-size: 9px;
        padding: 8px 0px;
        text-transform: uppercase;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-align: center;
        color: $black;

        @include after-mobile {
            font-size: 12px;
        }
    }

    &-AddToCartLink {
        border-right: solid 1px $c-gray-gainsboro;
    }

    .CartItem {
        padding: 10px 23px;
        border-bottom: none;
        margin-block-end: 0px;

        &-Wrapper {
            grid-template-columns: 110px auto;
            gap: 7%;
        }

        &-Content {
            padding-block-end: 0px;
        }

        &-Heading {
            font-size: 14px;
            font-weight: normal;
        }

        &-Picture {
            padding: 0px 0px 100% 0px;
        }

        &-Info {
            position: relative;
            margin: 10px 0px 0px 0px;
            display: flex;
        }

        &-Price, &-Quantity {
            margin: 0px;
            font-size: 14px;
        }

        &-Price data {
            font-weight: normal;
        }

        &-Quantity {
            margin-inline-end: 20px;
        }

        &-Options {
            margin-block-start: 4px;
        }

        &-OptionAttributeValue {
            font-weight: normal;
            color: $c-gray-97;
        }
    }
}
