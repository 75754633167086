/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --cart-header-backgrond: #{ $c-gray-e };
    --cart-page-divider-background: #{ $c-gray-d8 };
    --empty-cart-message-font-size: 1.4rem;
}

@mixin expanded-content {
    padding: 1rem;
    opacity: 1;
    max-height: 100%;
}

.CartPage {
    padding-block-end: var(--navigation-tabs-height);

    @include desktop {
        padding-block-end: 0;
        min-height: calc(100vh - var(--header-height));
        display: flex;
        flex-direction: column;
    }

    @include mobile {
        margin-block-start: 0;
        padding-inline: 0;
    }

    +.CmsBlock-Wrapper .Footer {
        display: none;
    }

    &-Heading {
        text-transform: uppercase;
        font-size: 1.4rem;
        text-align: center;
        font-weight: 500;
        height: auto;
        margin-block-start: 0;
        margin-block-end: 0;
        display: block;

        @include desktop {
            font-size: 2rem;
            display: inline-block;
        }

        @include small-desktop {
            font-size: 2.5rem;
            display: inline-block;
        }

        @include isArabic {
            direction: rtl;
        }
    }

    &-CartAdvantages {
        background-color: var(--cart-header-backgrond);
        padding: 6.6rem 0;
        display: none;
        order: 5;

        @include desktop {
            display: block;
            margin-block-start: auto;
        }
    }

    &-Floating {
        @include desktop {
            margin-block-start: 0;
        }
    }

    &-Static {
        &_isCartEmpty {
            position: absolute;
            inset-block-start: 50%;
            inset-inline-start: 50%;
            transform: translate(-50%, -50%);

            @include isArabic {
                inset-inline-start: unset;
                inset-inline-end: 50%;
            }
        }
    }

    &-Empty {
        text-align: center;
        font-weight: bold;
        font-size: var(--empty-cart-message-font-size);
        padding: 0;
    }

    &-Wrapper {
        min-height: 50vh;
        padding: 1.5rem 0 0;

        @include desktop {
            padding: 6.5rem 0;
            display: block;
        }

        @include small-desktop {
            display: grid;
            grid-template-columns: 1fr 30rem;
            grid-column-gap: 4rem;
        }

        &_isCartEmpty {
            @include small-desktop {
                display: block;
            }
        }
    }

    &-Header {
        background-color: var(--cart-header-backgrond);
        padding: 2.6rem 0 2.3rem;

        @include desktop {
            padding: 2.6rem 0 2.8rem;
        }

        @include small-desktop {
            padding: 3.1rem 1rem 3.5rem;
        }

        .ContentWrapper {
            a {
                font-size: var(--header-link-font-small);
            }
        }
    }

    &-ContinueShopping {
        width: fit-content;
        position: absolute;

        @include isArabic {
            position: relative;
        }

        inset-inline-end: 1rem;
        margin-block-start: 0;
        inset-block-start: 1rem;
        text-transform: none;
        color: #{$c-input-label};
        transition: color .2s;

        @include mobile {
            @include isArabic {
                margin-inline-start: 15px;
            }
        }

        @include tablet {
            @include isArabic {
                margin-inline-start: 10px;
            }
        }

        @include small-desktop-down {
            @include isArabic {
                margin-inline-start: 10px;
            }
        }

        @include desktop {
            @include isArabic {
                margin-inline-start: 10px;
            }
        }

        &::before {
            display: inline-block;
            content: '';
            width: 9px;
            height: 9px;
            transform: rotate(45deg);

            @include isArabic {
                transform: rotate(-135deg);
            }

            border-bottom: 1px solid #{$c-input-label};
            border-left: 1px solid #{$c-input-label};
            margin-inline-end: .5rem;

            @include mobile {
                @include isArabic {
                    inset-block-start: 4px;
                }
            }

            @media (max-width: 479px) {
                @include isArabic {
                    inset-block-start: 2px;
                }
            }
        }

        &:hover {
            text-decoration: none;
            color: #{$black};

            &::before {
                border-bottom: 1px solid #{$black};
                border-left: 1px solid #{$black};
            }
        }
    }

    &-Summary {
        position: sticky;
        inset-block-start: 100px;
        inset-block-end: auto;
        background-color: transparent;
        padding: 1rem;
    }

    .CartItem-Picture {
        padding: 60px;
    }

    .CartItem-Wrapper {
        grid-template-columns: 46% 47%;
        grid-column-gap: 7%;

        @include desktop {
            grid-template-columns: 22% 73%;
            grid-column-gap: 5%;
        }

        @include small-desktop {
            grid-column-gap: 7%;
            grid-template-columns: 25% 68%;
        }
    }

    .CartItem-Heading {
        font-weight: normal;
        font-size: 1.2rem;

        @include desktop {
            font-size: 1.9rem;
        }

        @include small-desktop {
            font-size: 2.3rem;
        }
    }

    .CartItem-OptionAttributeLabel,
    .CartItem-OptionAttributeValue {
        font-weight: normal;
        color: #{$c-input-label};
        font-size: 1rem;

        @include small-desktop {
            font-size: 1.2rem;
        }
    }

    .CartItem-Actions {
        @include desktop {
            display: block;
            margin-block-start: 0;
        }
    }

    .CartItem {
        border-bottom: none;
        padding-block-end: 3.3rem;

        @include desktop {
            padding-block-end: 4.2rem;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #{$c-input-border};
        }
    }

    &-Discount {
        /* stylelint-disable-next-line declaration-no-important */
        margin-block-start: 0 !important;
        padding: 7px 0;
        display: flex;
        justify-content: space-between;
        height: 50px;
        width: 100%;
        position: relative;

        @include desktop {
            padding: 7px 15px 7px 0;
        }

        &Button {
            margin-inline-end: 5px;
            width: 100%;
            position: absolute;
            height: 100%;
            inset-block-end: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            inset-inline-end: 0;

            &::after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                border: 1px solid transparent;
                border-bottom-color: #{$black};
                border-right-color: #{$black};
                background-color: transparent;
                transition: transform .5s;
                transform: rotate(45deg);

                @include isArabic {
                    margin-inline-start: 5px;
                }
            }

            &_isDiscountExpanded {
                &::after {
                    transition: transform .5s;
                    transform: rotate(225deg);
                }
            }

            span {
                display: none;
            }
        }

        span {
            font-size: 1.5rem;
            font-weight: bold;
            inset-block-start: 9px;
            height: max-content;
        }
    }

    &-DiscountCode {
        overflow: hidden;

        .CartCoupon {
            padding: 0;
            max-height: 0;
            transition: max-height .6s ease-out;

            @include desktop {
                padding: 0;
            }

            &-Button {
                margin-block-start: 0;
                margin-block-end: 22px;

                @include desktop {
                    margin: 0;
                }
            }

            .Form {
                @include desktop {
                    margin-block-start: 0;
                }
            }

            .Field {
                padding-block-start: 12px;

                @include desktop {
                    padding-block-start: 8px;
                }

                input {
                    border: 1px solid var(--input-color);

                    &::placeholder {
                        color: #{$c-input-label};
                        font-size: var(--font-size-medium);
                        opacity: 1;
                    }
                }
            }
        }

        &_isDiscountExpanded {
            .CartCoupon {
                max-height: 200px;
                transition: max-height .6s ease-out;
            }
        }
    }

    &-SummaryHeading {
        display: none;
        font-weight: bold;
        font-size: 2rem;
        text-align: start;
        border-bottom: 1px solid var(--cart-page-divider-background);
        padding-block-end: 2.5rem;
        margin-block-end: 1.5rem;

        @include small-desktop {
            display: block;
        }
    }

    &-TotalDetails {
        border-bottom: none;
        border-top: none;
        font-size: 1.2rem;
        display: grid;
        padding: .9rem 1rem;
        margin-block-start: 2.1rem;
        grid-template-columns: 50% 50%;
        grid-row-gap: .5rem;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;

        @include desktop {
            padding: .9rem 0;
            font-size: 1.4rem;
        }

        @include small-desktop {
            margin-block-start: 0;
        }

        dt {
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
        }

        dd {
            text-align: end;
        }
    }

    &-Total {
        font-size: 1.5rem;
        padding: 0 1rem 2rem;
        border-bottom: 1px solid var(--cart-page-divider-background);
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include desktop {
            padding: 0 0 2rem;
        }

        dt,
        dd {
            font-weight: bold;
        }

        dt {
            text-transform: uppercase;
        }

        dd {
            text-align: end;
        }

        @include desktop {
            font-size: 1.7rem;
        }
    }

    &-CheckoutButton {
        background-color: #{$c-red};
        font-size: 1.3rem;
        border-color: #{$c-red};
        margin: .5rem 0 0;
        width: 100%;

        span {
            display: none;
        }

        @include desktop {
            font-size: 1.5rem;
        }

        @include small-desktop {
            margin: 2rem 0 0;
        }

        &:not([disabled]) {
            &:hover {
                background-color: #{$c-red-cardinal};
                border-color: #{$c-red-cardinal};
            }
        }
    }

    &-CheckoutButtons {
        padding: 0;
    }

    &-PromoBlock {
        display: none;
    }

    .PromptFreeShippingMessage {
        grid-column: 1/3;

        @include desktop {
            padding-block-start: 10px;
        }
    }
}

.ProductCarousel {
    width: 100%;
}

.CartFooter {
    max-width: 944px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    &-Column {
        text-align: center;
        padding: 0 18px;
        flex-grow: 0.2;

        .Image {
            display: inline-block;
            vertical-align: middle;
            max-width: 80px;
            min-height: 60px;
            height: auto;
            padding: 0;

            &:not(:last-of-type) {
                margin-inline-end: 3px;
            }
        }

        p {
            text-transform: uppercase;
            font-size: 1.15rem;
            margin-block-end: 0;
            line-height: 1.1;
        }

        a {
            text-transform: uppercase;
            font-weight: bold;
            margin-block-end: 1rem;
            font-size: 1.15rem;
            display: inline-block;
        }
    }

    &-Title {
        font-weight: bold;
        font-size: 1.4rem;
        margin-block-end: 1.3rem;
        margin-block-start: 0;
        text-transform: uppercase;
    }
}
